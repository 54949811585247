import React, { useMemo } from "react";
import logo_light from "../../assets/images/logo-light.png";
import american_ex from "../../assets/images/payments/american-ex.png";
import discover from "../../assets/images/payments/discover.png";
import master_card from "../../assets/images/payments/master-card.png";
import paypal from "../../assets/images/payments/paypal.png";
import visa from "../../assets/images/payments/visa.png";
import { Mail } from "feather-icons-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer({services, setIsOpen}) {
  const footerLinks = [
    {
      liClass: "",
      route: "/page-terms",
      title: "Terms of Services",
    },
    {
      liClass: "mt-[10px]",
      route: "/page-privacy",
      title: "Privacy Policy",
    },
    {
      route: "/documentation",
      title: "Documentation",
      liClass: "mt-[10px]",
    },
    {
      route: "/changelog",
      title: "Changelog",
      liClass: "mt-[10px]",
    },
    {
      route: "#!",
      title: "Widget",
      liClass: "mt-[10px]",
    },
  ];
  const footerCompany = [
    {
      liClass: "",
      route: "/page-aboutus",
      title: "About us",
    },
    {
      liClass: "mt-[10px]",
      route: "/page-services",
      title: "Services",
    },
    {
      route: "/page-team",
      title: "Team",
      liClass: "mt-[10px]",
    },
    {
      route: "/page-pricing",
      title: "Pricing",
      liClass: "mt-[10px]",
    },
    {
      route: "/portfolio-creative-four",
      title: "Project",
      liClass: "mt-[10px]",
    },
    {
      route: "/blog",
      title: "Blog",
      liClass: "mt-[10px]",
    },
    {
      route: "/auth-login",
      title: "Login",
      liClass: "mt-[10px]",
    },
  ];

//   <ul className="footer-links-list site-info">
//   <li className="footer-links-item">
//     <a href="https://www.google.com/maps/place/41.264929,+69.352264/@41.2649377,69.3523341,22z/data=!4m4!3m3!8m2!3d41.2649291!4d69.3522637?authuser=0" target="_blank" className="footer-link">
//       <img
//         src={"/img/location.png"}
//         className="footer-link-img"
//         title="location link"
//         alt=""
//       />{" "}
//       <span>Toshkent, Qibray, Tolariq ko'chasi </span>
//     </a>
//   </li>
//   <li className="footer-links-item">
//     <a href="tel:+998913818350" target="_blank" className="footer-link">
//       <img
//         src={"/img/phone.png"}
//         className="footer-link-img"
//         title="Instagram link"
//         alt=""
//       />{" "}
//       <span>+998 (91) 381-83-50</span>
//     </a>
//   </li>
//   <li className="footer-links-item">
//     <a href="https://www.instagram.com/uv_print.uz/" target="_blank" className="footer-link">
//       <img
//         src={"/img/instagram.png"}
//         className="footer-link-img"
//         title="Instagram link"
//         alt=""
//       />{" "}
//       <span>uv_print.uz</span>
//     </a>
//   </li>
//   <li className="footer-links-item">
//     <a href="https://www.instagram.com/uv_print.uz/" target="_blank" className="footer-link">
//       <img
//         src={"/img/mail.png"}
//         className="footer-link-img"
//         title="Instagram link"
//         alt=""
//       />{" "}
//       <span>uv_print.uz</span>
//     </a>
//   </li>
//   <li className="footer-links-item">
//     <a href="https://t.me/uvprintuzb" target="_blank" className="footer-link">
//       <img
//         src={"/img/telegram.png"}
//         className="footer-link-img"
//         title="Telegram link"
//         alt=""
//       />{" "}
//       <span>UV_Print.uz</span>
//     </a>
//   </li>
//   <li className="footer-links-item">
//     <a href="https://www.facebook.com/profile.php?id=61551495737843" target="_blank" className="footer-link">
//       <img
//         src={"/img/facebook.png"}
//         className="footer-link-img"
//         title="Facebook link"
//         alt=""
//       />{" "}
//       <span>UV Print.uz</span>
//     </a>
//   </li>
// </ul>

  const { t } = useTranslation();



  const socialLinks = useMemo(() => [
    {
      id: 1,
      img: "/img/facebook-w.svg",
      url: "https://www.facebook.com/profile.php?id=61551495737843"
    },
    {
      id: 2,
      img: "/img/telegram-w.svg",
      url: "https://t.me/uvprintuzb"
    },
    {
      id: 3,
      img: "/img/instagram-w.svg",
      url: "https://www.instagram.com/uv_print.uz/"
    },
  ], [])

  return (
    <div>
      <footer className="footer  relative text-dark-footer dark:text-gray-200">
        <div className="footer-top">
          <div className="container">
           <div className="footer-wrapper">
            <div className="footer-section">
              <h2 className="footer-section-heading">{t("about-company")}</h2>
              <div className="footer-section-line"></div>
              <div className="footer-section-bottom">
                <p className="footer-section-text text-slate-400">
                За годы существования компания UVIX зарекомендовала себя как надежного и ответственного производителя.
                </p>
                <div className="footer-social-links-list">
                  {
                    socialLinks?.map(item => (
                    <div key={item.id} className="footer-social-links-item">
                    <Link className="footer-social-link bg-slate-400" to={item.url} target="_blank" >
                      <img src={item.img} alt="" className="footer-social-link-img" />
                    </Link>
                  </div>
                    ))
                  }
                  
                </div>
              </div>
            </div>
            <div className="footer-section">
              <h2 className="footer-section-heading">{t("services")}</h2>
              <div className="footer-section-line"></div>
              <div className="footer-section-bottom">
                {
                  services?.slice(0, 5)?.map(item => {
                    return (
                <a key={item.id} onClick={() => {setIsOpen(item?.id)}} href={"/#myTabContent"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                {item?.title}
                </a>
                    )
                  })
                }
                {/* <a href={"#services"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                {t("service-2-head")}
                </a>
                <a href={"#services"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                {t("service-3-head")}
                </a> */}
                
              </div>
            </div>
            <div className="footer-section">
              <h2 className="footer-section-heading">{t("useful-links")}</h2>
              <div className="footer-section-line"></div>
              <div className="footer-section-bottom">
                <a href={"#about-us"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                {t("about-us")}
                </a>
                <a href={"#services"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                {t("services")}
                </a>
                <a href={"#clients"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                {t("clients")}
                </a>
                
              </div>
            </div>
            <div className="footer-section">
              <h2 className="footer-section-heading">{t("contact-info")}</h2>
              <div className="footer-section-line"></div>
              <div className="footer-section-bottom">
                <p className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                <img src={"/img/home-button.png"} alt="" />
                <span>Toshkent, Tolariq ko'chasi</span>
                </p>
                <Link to={"tel:+998330408000"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                <img src={"/img/phone.png"} alt="" />
                <span>+998 (33) 040 80 00</span>
                </Link>
                <Link to={"tel:+998913818350"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                <img src={"/img/phone.png"} alt="" />
                <span>+998 (91) 381 83 50</span>
                </Link>
                <Link to={"tel:+998994400800"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                <img src={"/img/phone.png"} alt="" />
                <span>+998 (99) 440 08 00</span>
                </Link>
                <Link to={"mailto:uvixmedia@mail.ru"} className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                <img src={"/img/email.png"} alt="" />
                <span>uvixmedia@mail.ru</span>
                </Link>
                <p className="footer-section-text footer-bottom-link hover:text-indigo-600 transition-all text-slate-400">
                <img src={"/img/clock.png"} alt="" />
                <span>{t("working-time")}</span>
                </p>
                
              </div>
            </div>
           </div>
          </div>
        </div>
        <div className="py-[30px] px-0 border-t border-slate-800">
          <div className="container relative text-center">
            <div className="">
              <div className="text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} UVIX All rights reserved. Done by{" "}
                  <Link
                    to="https://innobek.uz"
                    target="_blank"
                    className="text-reset"
                  >
                    INNOBEK
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
