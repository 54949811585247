import React, { useEffect, useState } from "react";

import amazon from "../../assets/images/client/amazon.svg";
import google from "../../assets/images/client/google.svg";
import lenovo from "../../assets/images/client/lenovo.svg";
import paypal from "../../assets/images/client/paypal.svg";
import shopify from "../../assets/images/client/shopify.svg";
import spotify from "../../assets/images/client/spotify.svg";
import about01 from "../../assets/images/business/about01.jpg";
import about02 from "../../assets/images/business/about02.jpg";
import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import UserFeedBack from "../../component/userFeedBack";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ContactOne from "../contact/contactOne";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
export default function IndexBusiness() {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const sliders = [
    {
      img: "/img/banner-1.jpg",
      title: t("hero-1-title"),
      id: 1,
    },
    {
      img: "/img/banner-2.jpg",
      title: t("hero-2-title"),
      id: 2,
    },
    {
      img: "/img/banner-3.jpg",
      title: t("hero-3-title"),
      id: 3,
    },
  ];

  const brands = [
    {
      id: 1,
      img: "/img/brand-1.png",
    },
    {
      id: 2,
      img: "/img/brand-2.png",
    },
    {
      id: 3,
      img: "/img/brand-3.png",
    },
    {
      id: 4,
      img: "/img/brand-4.png",
    },
    {
      id: 5,
      img: "/img/brand-5.png",
    },
    {
      id: 6,
      img: "/img/brand-6.png",
    },
    {
      id: 7,
      img: "/img/brand-7.png",
    },
    {
      id: 8,
      img: "/img/brand-8.png",
    },
    {
      id: 9,
      img: "/img/brand-9.png",
    },
  ];

  const data = [
    {
      id: 1,
      title: t("service-1-head"),
      description: t("service-1-desc"),
      image1: "/img/services/kafel-1.jpg",
      image2: "/img/services/kafel-2.jpg",
    },
    {
      id: 2,
      title: t("service-2-head"),
      description: t("service-2-desc"),
      image1: "/img/services/oboy-1.jpg",
      image2: "/img/services/oboy-2.jpg",
      // image: image7,
    },
    {
      id: 3,
      title: t("service-3-head"),
      description: t("service-3-desc"),
      image1: "/img/services/org-1.jpg",
      image2: "/img/services/org-2.jpg",
    },
    {
      id: 4,
      title: t("service-4-head"),
      description: t("service-4-desc"),
      image1: "/img/services/metall-2.jpg",
      image2: "/img/services/service-4-2.jpg",
    },
    {
      id: 5,
      title: t("service-5-head"),
      description: t("service-5-desc"),
      image1: "/img/services/tree-1.jpg",
      image2: "/img/services/tree-2.jpg",
    },
    {
      id: 6,
      title: t("service-6-head"),
      description: t("service-6-desc"),
      image1: "/img/services/steklo-1.jpg",
      image2: "/img/services/steklo-2.jpg",
    },
    {
      id: 7,
      title: t("service-7-head"),
      description: t("service-7-desc"),
      image1: "/img/services/service-7-1.jpg",
      image2: "/img/services/service-7-2.jpg",
    },
    {
      id: 8,
      title: t("service-8-head"),
      description: t("service-8-desc"),
      image1: "/img/services/service-8-1.jpg",
      image2: "/img/services/service-8-2.jpg",
    },
    {
      id: 9,
      title: t("service-9-head"),
      description: t("service-9-desc"),
      image1: "/img/services/akril-1.jpg",
      image2: "/img/services/akril-2.jpg",
      
    },
    {
      id: 10,
      title: t("service-10-head"),
      description: t("service-10-desc"),
      image1: "/img/services/xolst-1.jpg",
      image2: "/img/services/xolst-2.jpg",
    },
    {
      id: 11,
      title: t("service-11-head"),
      description: t("service-11-desc"),
      image1: "/img/services/mdf-1.jpg",
      image2: "/img/services/mdf-2.jpg",
    },
    {
      id: 12,
      title: t("service-12-head"),
      description: t("service-12-desc"),
      image1: "/img/services/vitraj-1.jpg",
      image2: "/img/services/vitraj-2.jpg",
    },
    {
      id: 13,
      title: t("service-13-head"),
      description: t("service-13-desc"),
      image1: "/img/services/onyx-steklo-1.jpg",
      image2: "/img/services/onyx-steklo-2.jpg",
    },
    {
      id: 14,
      title: t("service-14-head"),
      description: t("service-14-desc"),
      image1: "/img/services/orakl-1-min.jpg",
      image2: "/img/services/orakl-2-min.jpg",
    },
  ];

  const features = [
    {
      id: 1,
      title: t("feature-1-head"),
      description: t("feature-1-description"),
      image: "/img/features/feature-1.png",
    },
    {
      id: 2,
      title: t("feature-2-head"),
      description: t("feature-2-description"),
      image: "/img/features/feature-2.png",
    },
    {
      id: 3,
      title: t("feature-3-head"),
      description: t("feature-3-description"),
      image: "/img/features/feature-3.png",
    },
    {
      id: 4,
      title: t("feature-4-head"),
      description: t("feature-4-description"),
      image: "/img/features/feature-4.png",
    },
    {
      id: 5,
      title: t("feature-5-head"),
      description: t("feature-5-description"),
      image: "/img/features/feature-5.png",
    },
    {
      id: 6,
      title: t("feature-6-head"),
      description: t("feature-6-description"),
      image: "/img/features/feature-6.png",
    },
  ];

  const howWeWorkData = [
    {
      id: 1,
      description: t("hw-1"),
    },
    {
      id: 2,
      description: t("hw-2"),
    },
    {
      id: 3,
      description: t("hw-3"),
    },
    {
      id: 4,
      description: t("hw-4"),
    },
    {
      id: 5,
      description: t("hw-5"),
    },
  ];

  // const [isOpen, setOpen] = useState(false);
  const [isOpenTab, setIsOpen] = useState(1);

  

  const navigate = useNavigate();

  const [isLong, setLong] = useState(false);

  return (
    <>
      <Link to="tel:+998330408000" className="fixed-link">
        <i className="uil uil-phone"></i>
      </Link>
      <Navbar navClass="nav-light" />
      <section id="home" className="relative">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={55}
          totalSlides={3}
          interval={10000}
          isPlaying={true}
          className="h-screen"
        >
          <Slider className="h-full careHeight">
            {sliders?.map((item, index) => {
              return (
                <Slide index={index} className="h-full" key={item.id}>
                  <div className="flex items-center justify-center transition-all duration-700 ease-in-out h-full overflow-hidden">
                    <img
                      src={item.img}
                      className={`absolute  w-full  z-1`}
                      style={{objectPosition: "center", objectFit: "cover", height: "100vh", top: 0, left: 0 }}
                    />
                    {/* <div className="absolute inset-0 md:bg-gradient-to-b md:from-transparent md:to-black md:bg-black/20 bg-black/70 z-2"></div> */}
                    <div className="container relative z-3 flex items-center ">
                      <div className="grid grid-cols-1 md:mt-10 mt-14">
                        <div className="md:text-start text-center">
                          <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl md:mb-6 mb-2">
                            {item.title}
                          </h1>
                          <p className="text-white/70 text-lg max-w-xl">
                            {t("hero-desc")}
                          </p>

                          <div className="md:mt-8 mt-4">
                            <a
                              href="#contact"
                              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                            >
                              {t("contact")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
              );
            })}
            {/* <Slide index={1} className='h-full'>
                            <div className="flex items-center justify-center transition-all duration-700 ease-in-out h-full overflow-hidden">
                                <div className="absolute inset-0 image-wrap z-1 bg-no-repeat bg-center bg-cover  bg-[url('../../assets/images/business/bg02.jpg')]"></div>
                                <div className="absolute inset-0 md:bg-gradient-to-b md:from-transparent md:to-black md:bg-black/20 bg-black/70 z-2"></div>
                                <div className="container relative z-3 flex items-center justify-center">
                                    <div className="grid grid-cols-1 mt-10">
                                        <div className="text-center">
                                            <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Let's Start With Techwind</h1>
                                            <p className="text-white/70 text-lg max-w-xl mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS v3.x html page.</p>

                                            <div className="mt-8">
                                                <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">See Services</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </Slide>
                       <Slide index={2} className='h-full'>
                            <div className="flex items-center justify-center transition-all duration-700 ease-in-out h-full overflow-hidden">
                                <div className="absolute inset-0 image-wrap z-1 bg-no-repeat bg-center bg-cover bg-[url('../../assets/images/business/bg03.jpg')] "></div>
                                <div className="absolute inset-0 ltr:md:bg-gradient-to-l rtl:md:bg-gradient-to-r md:from-black md:via-black/80 md:bg-black/20 bg-black/70 z-2"></div>
                                <div className="container relative z-3">
                                    <div className="grid grid-cols-1 mt-10">
                                        <div className="md:text-end text-center">
                                            <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Build and Grow Your Business</h1>
                                            <p className="text-white/70 text-lg max-w-xl md:ms-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS v3.x html page.</p>

                                            <div className="mt-8">
                                                <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Pricing Plans</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </Slide> */}
          </Slider>
        </CarouselProvider>
      </section>

      <section className="relative py-16" id="about-us">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="md:col-span-5">
              <div className="relative">
                <img
                  src={"/img/about.jpg"}
                  className="rounded-md  h-[400px] object-cover"
                  style={{objectPosition: "left center"}}
                  alt=""
                />

                {/* <div className="absolute bottom-24 end-0">
                                    <img src={about02} className="rounded-md shadow-md w-48 h-48" alt="" />
                                    <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                        <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="S_CGed6E610"
                                            className="lightbox h-14 w-14 rounded-full shadow-md dark:shadow-gyay-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600">
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-xl"></i>
                                        </Link>
                                    </div>
                                </div> */}
              </div>
            </div>
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              videoId="S_CGed6E610"
              onClose={() => setOpen(false)}
            />

            <div className="md:col-span-7">
              <div className="lg:ms-4">
                <h4 className="mb-2 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">
                  {t("about-head")}
                </h4>
                <p className="mb-6 md:text-2xl text-xl lg:leading-normal leading-normal font-medium">
                  {t("about-subhead")}
                </p>
                <p
                  className={`text-slate-400 max-w-xl ${
                    isLong ? "" : "line-clamp-4"
                  } `}
                >
                  {t("about-desc")}
                </p>
                <button
                  onClick={() => {
                    setLong((e) => !e);
                  }}
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-3"
                >
                  {t("more")}{" "}
                  <i
                    className={`mdi ${
                      isLong ? "mdi-chevron-left" : "mdi-chevron-right"
                    } transition-all  align-middle`}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="services"
          style={{ paddingTop: "50px" }}
          className="container relative"
        >
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {t("our-services")}
            </h3>
          </div>
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-4 md:col-span-5">
              <div className="sticky top-20">
                <ul
                  className="flex-column text-center p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  {data?.map((item) => {
                    return (
                      <li role="presentation">
                        <button
                          className={`${
                            isOpenTab === item.id
                              ? "text-white bg-indigo-600 hover:text-white"
                              : ""
                          } text-start px-4 py-2 text-base font-semibold rounded-md w-full hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                          id="profile-tab"
                          data-tabs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="true"
                          onClick={() => {
                            setIsOpen(item.id);
                          }}
                        >
                          {item.title}
                        </button>
                      </li>
                    );
                  })}
                  {/* <li role="presentation">
                    <button
                      onClick={() => handleTabClick(1)}
                      className={`${
                        isOpenTab === 1
                          ? "text-white bg-indigo-600 hover:text-white"
                          : ""
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="dashboard-tab"
                      data-tabs-target="#dashboard"
                      type="button"
                      role="tab"
                      aria-controls="dashboard"
                      aria-selected="false"
                    >
                      Dedicated Office
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      onClick={() => handleTabClick(2)}
                      className={`${
                        isOpenTab === 2
                          ? "text-white bg-indigo-600 hover:text-white"
                          : ""
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="settings-tab"
                      data-tabs-target="#settings"
                      type="button"
                      role="tab"
                      aria-controls="settings"
                      aria-selected="false"
                    >
                      Kitchen Space
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      onClick={() => handleTabClick(3)}
                      className={`${
                        isOpenTab === 3
                          ? "text-white bg-indigo-600 hover:text-white"
                          : ""
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="contacts-tab"
                      data-tabs-target="#contacts"
                      type="button"
                      role="tab"
                      aria-controls="contacts"
                      aria-selected="false"
                    >
                      Meeting Rooms
                    </button>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="lg:col-span-8 md:col-span-7">
              <div
                id="myTabContent"
                className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
              >
                {data.map((item, index) => {
                  return (
                    <div
                      className={`${isOpenTab === item.id ? "" : "hidden"}`}
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      key={index}
                    >
                      <div className="services-images">
                        <img
                          src={item.image1}
                          className=" shadow rounded-md"
                          alt=""
                          
                        />
                        <img
                          src={item.image2}
                          className=" shadow rounded-md"
                          alt=""
                          
                        />
                      </div>
                      <div className="mt-6">
                        <h5 className="text-lg font-semibold mb-4">
                          {item.title}
                        </h5>
                        <p className="text-slate-400 mb-2">
                          {item.description}
                        </p>
                        {/* <Link
                          to="#"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                        >
                          See More{" "}
                          <i className="uil uil-arrow-right align-middle"></i>
                        </Link> */}
                      </div>
                    </div>
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    marginTop: "50px",
                  }}
                  className=""
                >
                  {data.map((item, index) => {
                    if (isOpenTab != item.id) {
                      return (
                        <div
                          className={`border-t pt-4 first:border-b-0`}
                          // style={{ borderBottom: "1px solid gray" }}
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                          key={index}
                        >
                          <button
                            onClick={() => {
                              setIsOpen(item?.id);
                            }}
                            className="services-images-btn"
                          >
                            <a href="/#myTabContent" className="services-images">
                              <img
                                src={item.image1}
                                className=" shadow rounded-md"
                                alt=""
                                
                              />
                              <img
                                src={item.image2}
                                className=" shadow rounded-md"
                                alt=""
                                
                              />
                            </a>
                          </button>
                          <div className="mt-6">
                            <button
                              onClick={() => {
                                setIsOpen(item?.id);
                              }}
                              className="text-lg font-semibold mb-4"
                            >
                              <a href="/#myTabContent">{item.title}</a>
                            </button>
                            {/* <p className="text-slate-400 mb-2">
                          {item.description}
                        </p> */}
                            {/* <Link
                          to="#"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                        >
                          See More{" "}
                          <i className="uil uil-arrow-right align-middle"></i>
                        </Link> */}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="how-we-work" className="container pt-6 relative">
          <div className="grid grid-cols-1 pb-8 pt-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {t("how-we-work")}
            </h3>
          </div>
          <div className="hw-list">
            {howWeWorkData?.map((item, index) => {
              return (
                <div key={item.id} className="hw-item shadow-md">
                  <div className="hw-item-number bg-indigo-600">
                    {index + 1}
                  </div>
                  <p className="hw-item-desc text-slate-800 mt-4">
                    {item.description}
                  </p>
                  <div className=""></div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* <section className="py-20 w-full table relative bg-[url('../../assets/images/team.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-slate-900/70"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-4 md:text-3xl text-2xl text-white font-medium">Stop leaving money on the table.</h3>

                        <p className="text-white/80 max-w-xl mx-auto">Start working with Techwind that can provide everything you need to generate awareness, drive traffic, connect.</p>

                        <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="S_CGed6E610" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 mx-auto mt-10">
                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                        </Link>
                    </div>
                </div>
            </section> */}

      <section className="relative  py-8 pb-16">
        {/* <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Choose Pricing Plan</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Start working with Techwind that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>

                    <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
                        <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900">
                            <div className="p-6 py-8">
                                <h6 className="font-bold uppercase mb-5 text-indigo-600">Starter</h6>

                                <div className="flex mb-5">
                                    <span className="text-xl font-semibold">$</span>
                                    <span className="price text-4xl font-semibold mb-0">39</span>
                                    <span className="text-xl font-semibold self-end mb-1">/mo</span>
                                </div>

                                <ul className="list-none text-slate-400 space-x-1">
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Full Access</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Source Files</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Free Appointments</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Enhanced Security</li>
                                </ul>
                                <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Get Started</Link>
                            </div>
                        </div>

                        <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900">
                            <div className="p-6 py-8 md:ps-10">
                                <h6 className="font-bold uppercase mb-5 text-indigo-600">Business</h6>

                                <div className="flex mb-5">
                                    <span className="text-xl font-semibold">$</span>
                                    <span className="price text-4xl font-semibold mb-0">49</span>
                                    <span className="text-xl font-semibold self-end mb-1">/mo</span>
                                </div>

                                <ul className="list-none text-slate-400 space-x-1">
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Full Access</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Source Files</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Free Appointments</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Enhanced Security</li>
                                </ul>
                                <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Try it Now</Link>
                            </div>
                        </div>

                        <div className="group border-b-[3px] dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 transition-all duration-500 ease-in-out hover:scale-105 relative shadow dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900">
                            <div className="p-6 py-8 md:ps-10">
                                <h6 className="font-bold uppercase mb-5 text-indigo-600">Professional</h6>

                                <div className="flex mb-5">
                                    <span className="text-xl font-semibold">$</span>
                                    <span className="price text-4xl font-semibold mb-0">59</span>
                                    <span className="text-xl font-semibold self-end mb-1">/mo</span>
                                </div>

                                <ul className="list-none text-slate-400 space-x-1">
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Full Access</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Source Files</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Free Appointments</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-indigo-600 text-xl me-2"></i> Enhanced Security</li>
                                </ul>
                                <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Try it Now</Link>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="">
          <UserFeedBack />
        </div>

        <div className="py-8">
          <div className="container relative py-8">
            <h3 className="mb-8 md:text-3xl md:leading-normal text-2xl leading-normal text-center  font-semibold">
              {t("clients")}
            </h3>

            <div className="clients-wrapper">
              {brands?.map((el) => (
                <div key={el.id} className="max-w-[200px] min-w-[100px] w-full">
                  <img
                    src={el.img}
                    className="w-full h-[100px] object-center object-contain "
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
            {/* <div className="group text-center">
              <PieChart className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-indigo-600 transition-all duration-500 ease-in-out mx-auto"></PieChart>

              <div className="mt-6">
                <Link
                  to="#"
                  className="text-xl font-semibold group-hover:text-indigo-600 transition-all duration-500 ease-in-out"
                >
                  Profitable Marketing
                </Link>

                <p className="text-slate-400 mt-4">
                  We develop digital strategies, products and services
                  appreciated by clients.
                </p>

                <div className="mt-4">
                  <Link
                    to="#"
                    className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    Read More{" "}
                    <i className="uil uil-arrow-right align-middle"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="group text-center">
              <Briefcase className="h-10 w-10 stroke-1 group-hover:stroke-[1.5px] group-hover:text-indigo-600 transition-all duration-500 ease-in-out mx-auto"></Briefcase>

              <div className="mt-6">
                <Link
                  to="#"
                  className="text-xl font-semibold group-hover:text-indigo-600 transition-all duration-500 ease-in-out"
                >
                  SEO Specialists
                </Link>

                <p className="text-slate-400 mt-4">
                  We develop digital strategies, products and services
                  appreciated by clients.
                </p>

                <div className="mt-4">
                  <Link
                    to="#"
                    className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    Read More{" "}
                    <i className="uil uil-arrow-right align-middle"></i>
                  </Link>
                </div>
              </div>
            </div> */}

            {features?.map((el) => (
              <div className="group text-center">
                <img
                  src={el.image}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                  className="    mx-auto"
                />

                <div className="mt-6">
                  <p className="text-xl font-semibold group-hover:text-indigo-600 transition-all duration-500 ease-in-out">
                    {el.title}
                  </p>

                  <p className="text-slate-400 mt-4">{el.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <Blog className="container relative md:mt-24 mt-16" /> */}
      </section>
      <ContactOne />
      <Footer services={data} setIsOpen={setIsOpen} />
      {/* <CookieModal /> */}
    </>
  );
}
