import React, { useState } from 'react'
import contact from '../../assets/images/contact.svg';
import { User, Mail, Book, MessageCircle, Phone } from 'feather-icons-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function ContactOne() {
   

    const {t} = useTranslation()

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    const handleFormSubmit = (e) => {
        e.preventDefault()
        if(phone) {
            console.log("sa;");
            const myText = `Ariza: %0A Ismi: ${ name } %0A Telefon Raqam: ${ phone }%0A Xabar: ${message}`
            const token = "6647253176:AAFDsPO_Xdgbl6HjBGOik-NE8ij-rnn7Ayk";
            const chatId = "-4099655352"
            const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${myText}`
            console.log(url);
            const api = new XMLHttpRequest();
            api.open("Get", url, true);
            api.send() 
            setPhone("")
            setName("")
            setMessage("")
            alert("Ваша заявка отправлена, мы свяжемся с вами в ближайшее время")
        }else {
            alert("Заполните все поля")
        }
    }

    return (
        <>
            {/* <Navbar navClass="nav-light" /> */}
{/* 
            <section id='contact' className="relative table w-full py-36 bg-[url('../../assets/images/company/aboutus.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-black opacity-75"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">{t("contact-us")}</h3>
                    </div>
                </div>

               
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative  py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        {contactData.map((item,index)=>{
                            return(
                                <div key={index} className="text-center px-6 mt-6">
                                    <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                        <i className={item.icon}></i>
                                    </div>

                                    <div className="content mt-7">
                                        <h5 className="title h5 text-xl font-medium">{item.title}</h5>
                                        <p className="text-slate-400 mt-3">{item.desc}</p>

                                        <div className="mt-5">
                                            <Link to="/tel:+152534-468-854" className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">{item.contact}</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">{t("contact")} !</h3>

                                    <form onSubmit={handleFormSubmit} >
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="col-span-12 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">{t("name")}:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <User className="w-4 h-4 absolute top-3 start-4"></User>
                                                        <input value={name} onChange={(e) => {setName(e.target.value)}} name="name" id="name" type="text" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder={t("name")} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Mail className="w-4 h-4 absolute top-3 start-4"></Mail>
                                                        <input name="email" id="email" type="email" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">{t("phone")}:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <Phone className="w-4 h-4 absolute top-3 start-4"></ Phone>
                                                        <input  value={phone} onChange={(e) => {setPhone(e.target.value)}} name="subject" id="subject" type='tel' className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder={t("phone")} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="comments" className="font-semibold">{t("message")}:</label>
                                                    <div className="form-icon relative mt-2">
                                                        <MessageCircle className="w-4 h-4 absolute top-3 start-4"></MessageCircle>
                                                        <textarea value={message} onChange={(e) => {setMessage(e.target.value)}} name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder={t("message")}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center">{t("send")}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            
            <div id='contact' className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe  title='google'
                        src='https://yandex.uz/map-widget/v1/?ll=69.355840%2C41.264180&mode=whatshere&whatshere%5Bpoint%5D=69.351887%2C41.265109&whatshere%5Bzoom%5D=17&z=15.53' 
                        // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d308.20389173685237!2d69.35210102730129!3d41.2648036250429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1695480889915!5m2!1sru!2s"
                         style={{border:0}} className="w-full h-[500px]" allowFullScreen></iframe>
                         {/* <div style={{position: "relative", overflow: "hidden" }}><a href="https://yandex.uz/maps/10335/tashkent/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Ташкент</a><a href="https://yandex.uz/maps/10335/tashkent/house/YkAYdQJhQEYDQFprfX53dH1iZA==/?ll=69.355840%2C41.264180&utm_medium=mapframe&utm_source=maps&z=15.53" style="color:#eee;font-size:12px;position:absolute;top:14px;">Улица Куйи Таларык, 33 — Яндекс Карты</a><iframe src="https://yandex.uz/map-widget/v1/?ll=69.355840%2C41.264180&mode=whatshere&whatshere%5Bpoint%5D=69.351887%2C41.265109&whatshere%5Bzoom%5D=17&z=15.53" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div> */}
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}
